import React, { useMemo } from 'react';
import { AiFillVideoCamera } from 'react-icons/ai';
import { formatDate, truncateStart, truncate } from '../../helpers/utils';
import { appSettings } from '../../helpers/settings';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';

// HOOKS
import useWeb3 from '../../hooks/useWeb3';
import useVideos from '../../hooks/useVideos';

function UserSubscriptionsTable() {
    const { account } = useWeb3();
    const { allVideos, channels } = useVideos();

    const userVideos = useMemo(() => {
        return allVideos?.filter((video) => video?.subscribers.map((user) => user?.address)?.includes(account));
    }, [allVideos, account]);

    /*** ------------------------------------------------ */
    //      USER SUBSCRIBED CHANNELS
    /*** ------------------------------------------------ */
    const columns = [
        {
            name: 'Video',
            selector: (row) => row?.title,
            minWidth: '300px',
            cell: (row) => (
                <div row={row}>
                    <Link className='text-reset' to={`/videos/${row.videoId}`}>
                        <div className='d-flex align-items-center'>
                            <div
                                className='flex-shrink-0 bg-cover bg-center'
                                style={{
                                    width: '40px',
                                    height: '40px',
                                    background: `url(${row?.poster})`,
                                    borderRadius: '0.5rem',
                                }}
                            ></div>
                            <div className='ms-3'>
                                <h6 className='mb-1'>{row?.title}</h6>
                                <p className='text-muted small mb-0'>{truncateStart(row?.description, 30, '...')}</p>
                            </div>
                        </div>
                    </Link>
                </div>
            ),
        },
        {
            name: 'Channel',
            minWidth: '200px',
            selector: (row) => row?.channelId,
            cell: (row) => (
                <div row={row}>
                    <Link
                        to={`/channels/${channels?.filter((channel) => channel?.id === row?.channelId)[0]?.slug}`}
                        className='text-reset'
                    >
                        <div className='d-flex align-items-center'>
                            <div
                                className='flex-shrink-0 bg-cover bg-center'
                                style={{
                                    width: '40px',
                                    height: '40px',
                                    backgroundImage: `url(${
                                        channels?.filter((channel) => channel?.id === row?.channelId)[0]?.avatar
                                    })`,
                                    borderRadius: '0.5rem',
                                }}
                            ></div>
                            <div className='ms-3'>
                                <h6 className='mb-1' style={{ fontSize: '0.9rem' }}>
                                    {channels?.filter((channel) => channel?.id === row?.channelId)[0]?.title}
                                </h6>
                                <p className='text-muted small mb-0'>
                                    {channels?.filter((channel) => channel?.id === row?.channelId)[0]?.ownerName &&
                                        truncate(
                                            channels?.filter((channel) => channel?.id === row?.channelId)[0]?.ownerName,
                                            15,
                                            '.....'
                                        )}
                                </p>
                            </div>
                        </div>
                    </Link>
                </div>
            ),
        },
        {
            name: 'Subscribed At',
            minWidth: '200px',
            selector: (row) => row?.id,
            cell: (row) => (
                <div row={row}>
                    <small>
                        {formatDate(row?.subscribers?.filter((user) => user?.address === account)[0]?.subscribedAt)}
                    </small>
                </div>
            ),
        },
        {
            name: 'Subscription Price',
            selector: (row) => row?.price,
            cell: (row) => (
                <div row={row}>
                    <small>
                        {row?.price} {appSettings.currency}
                    </small>
                </div>
            ),
        },
        // {
        //     name: 'Videos',
        //     selector: (row) => row?.videos,
        //     cell: (row) => (
        //         <div row={row}>
        //             <small>{row?.videos?.filter((video) => video?.isApproved)?.length}</small>
        //         </div>
        //     ),
        // },
        // {
        //     name: 'Subscribers',
        //     selector: (row) => row?.subscribers,
        //     cell: (row) => (
        //         <div row={row}>
        //             <small>{row?.subscribers?.length}</small>
        //         </div>
        //     ),
        // },
    ];

    return (
        <div className='card shadow-lg mb-0' data-aos='fade-up' data-aos-delay='200'>
            <div className='card-body p-lg-5'>
                <div className='d-flex a;ign-items-center mb-5'>
                    <div className='stats-icon solid-cyan'>
                        <AiFillVideoCamera size='1.4rem' />
                    </div>
                    <div className='ms-3'>
                        <h2 className='mb-0 h4'>My Subscriptions</h2>
                        <p className='text-muted fw-normal mb-0'>
                            user subscription details
                        </p>
                    </div>
                </div>

                {userVideos && userVideos.length > 0 ? (
                    <DataTable
                        columns={columns}
                        data={userVideos?.sort((a, b) => {
                            return new Date(b.createdAt) - new Date(a.createdAt);
                        })}
                        pagination={userVideos.length >= 1 && true}
                        responsive
                        theme='solarized'
                    />
                ) : (
                    <p className='mb-0'>There're no subscriptions to display</p>
                )}
            </div>
        </div>
    );
}

export default UserSubscriptionsTable;
