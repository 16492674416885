import React from 'react';
import { RiUploadCloud2Fill } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { truncateStart } from '../../helpers/utils';

// HOOKS
import useWeb3 from '../../hooks/useWeb3';
import useVideos from '../../hooks/useVideos';

function ChannelCard({ id, title, bio, avatar, cover, owner, slug, videos, category }) {
    const { owner: appOwner } = useVideos();
    const { account } = useWeb3();

    return (
        <>
            <div className='card channel-card mb-0'>
                <div className='card-body p-3'>
                    <div className='channel-card-cover' style={{ backgroundImage: `url(${cover})` }}>
                        <div className='channel-card-avatar' style={{ backgroundImage: `url(${avatar})` }}></div>
                        <div className='channel-card-category'>{category}</div>
                    </div>

                    <div className='pt-5 pb-3 d-flex align-items-center justify-content-between'>
                        <Link to={`/channels/${slug}`} className='text-reset'>
                            <h2 className='h3 mb-0'>{truncateStart(title, 20)}</h2>
                        </Link>
                        {account === appOwner && (
                            <Link className='btn btn-success btn-sm px-3 flex-shrink-0' to={`/upload/${slug}`}>
                                <RiUploadCloud2Fill className='mb-1 me-2' />
                                Upload Video
                            </Link>
                        )}
                    </div>
                    <p className='text-muted mb-0'>{truncateStart(bio, 50)}</p>
                </div>
            </div>
        </>
    );
}

export default ChannelCard;
