/* -----------------------------------------------------------------------------------------

    THSI FILE CONTAINS SOME STATIC DATA TO FILL SOME DIFFERENT COMPONENTS

----------------------------------------------------------------------------------------- */

import React from 'react';

/* FAQS LIST - ADD OR DELETE FROM THEM AS YOU NEED */
export const FAQS = [
    {
        question: 'What is Web3 SVOD Video Streaming App',
        answer: (
            <div className='text-muted'>
                <p className='mb-2'>
                VOD  Video on Demand  is a Web3 video streaming app, suitable for movies, videos, reviews and streaming. The app allows users to host their videos on the Blockchain and deal with them using CryptoCurrency.
                </p>
            </div>
        ),
    },
    {
        question: 'What stack is the App built with?',
        answer: (
            <div className='text-muted'>
                <p className='mb-2'>
                    <strong className='text-white'>Solidity</strong> – building smart contracts
                </p>
                <p className='mb-2'>
                    <strong className='text-white'>Truffle suite</strong> – development environment, testing framework,
                    and asset pipeline for blockchains.
                </p>
                <p className='mb-2'>
                    <strong className='text-white'>Web3.js</strong> – JavaScript Library that allows interacting with a
                    local or remote Ethereum node
                </p>
                <p className='mb-2'>
                    <strong className='text-white'>Laravel</strong> – Server to save images with metadata in the
                    blockchain
                </p>
                <p className='mb-2'>
                    <strong className='text-white'>MetaMask</strong> – Crypto wallet for trading NFTs
                </p>
                <p className='mb-2'>
                    <strong className='text-white'>React.js</strong> – JavaScript framework to build the user interface
                    and connect everything together
                </p>
                <p className='mb-2'>
                    <strong className='text-white'>Bootstrap 5</strong> – UI
                </p>
            </div>
        ),
    },
    {
        question: 'What blockchain are supported?',
        answer: (
            <div className='text-muted'>
                <p className='mb-2'>$VOD supported blockchain:</p>
                <ul className='mb-0 text-white'>
                    <li>Binance Smart Chain</li>
                    </ul>
            </div>
        ),
    },
    {
        question: 'What wallet are supported?',
        answer: (
            <div className='text-muted'>
                <p className='mb-2'>At the moment, you can use MetaMask wallet.</p>
            </div>
        ),
    },
    {
        question: 'How to buy $VOD token',
        answer: (
            <div className='text-muted'>
                <p className='mb-2'>Centralized & Decentralized Exchanges (CEX & DEX):</p>
                <p className='mb-2'>
                    <strong className='text-white'>Centralized</strong> – We will update CEX listing on our Twitter account Frequently.
                </p> <p className='mb-2'>
                    <strong className='text-white'>Decentralized</strong> – We will update DEX listing on our Twitter account Frequently.
                </p>
            </div>
        ),
    },
    {
        question: 'Is $VOD is supports live streaming?',
        answer: (
            <div className='text-muted'>
                <p className='mb-2'>
                    No, currently we do support only Video on Demand Subscription based. We will update in a future.
                </p>
              </div>
        ),
    },
    {
        question: 'Do you provide support for your $VOD Streaming app?',
        answer: (
            <div className='text-muted'>
                <p className='mb-2'>Yes we do on regular basis. please email us support@vodpo.com.</p>
            </div>
        ),
    },
    {
        question: "What in terms of IPFS storage",
        answer: (
            <div className='text-muted'>
                <p className='mb-2'>
                We have also made a big change in terms of storage, by implementing IPFS as a decentralized storage solution.
                </p>
            </div>
        ),
    },
    {
        question: 'More details?',
        answer: (
            <div className='text-muted'>
                <p className='mb-2'>
                  We will be happy to help. contact us at support@vodpo.com
                </p>
            </div>
        ),
    },
];
