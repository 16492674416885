import React from 'react';
import { truncate } from '../../helpers/utils';
import { Jazzicon } from '@ukstv/jazzicon-react';

function UserBlock({ address, name }) {
    return (
        <>
            <div className='d-flex align-items-center'>
                <div className='list-inline-item'>
                    <div className='avatar avatar-md2'>
                        <div style={{ width: '40px', height: '40px' }}>
                            <Jazzicon address={address} />
                        </div>
                    </div>
                </div>
                <div className='list-inline-item'>
                    <h6 className='mb-1 lh-1 fw-normal'>{name}</h6>
                    <p className='small text-muted mb-0 lh-1'>{truncate(address, 15)}</p>
                </div>
            </div>
        </>
    );
}

export default UserBlock;
